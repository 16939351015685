var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('a-card',{staticClass:"card-signup header-solid h-full",attrs:{"bordered":false,"bodyStyle":{paddingTop: 0}}},[_c('p',{staticClass:"text-center my-25 font-semibold text-muted"}),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"First Name","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'first_name',
						{ rules: [{ required: true, message: 'Please input your first name!' }] } ]),expression:"[\n\t\t\t\t\t\t'first_name',\n\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please input your first name!' }] },\n\t\t\t\t\t\t]"}],attrs:{"placeholder":"John"}})],1),_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Last Name","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'last_name',
						{ rules: [{ required: true, message: 'Please input your last name!' }] } ]),expression:"[\n\t\t\t\t\t\t'last_name',\n\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please input your last name!' }] },\n\t\t\t\t\t\t]"}],attrs:{"placeholder":"Doe"}})],1),_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Email","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'email',
						{ rules: [{ required: true, message: 'Please input your email!' }] } ]),expression:"[\n\t\t\t\t\t\t'email',\n\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please input your email!' }] },\n\t\t\t\t\t\t]"}],attrs:{"placeholder":"john@gmail.com"}})],1),_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Address","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'address',
						{ rules: [{ required: true, message: 'Please input your full address!' }] } ]),expression:"[\n\t\t\t\t\t\t'address',\n\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please input your full address!' }] },\n\t\t\t\t\t\t]"}],attrs:{"placeholder":"No 1 union street"}})],1),_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Zip Code","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'zip_code',
						{ rules: [{ required: true, message: 'Please input your address Zip Code!' }] } ]),expression:"[\n\t\t\t\t\t\t'zip_code',\n\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please input your address Zip Code!' }] },\n\t\t\t\t\t\t]"}],attrs:{"placeholder":"45562"}})],1),_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"SSN (Optional)","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'ssn',
						{ rules: [{ required: false, message: 'Please input your SSN!' }] } ]),expression:"[\n\t\t\t\t\t\t'ssn',\n\t\t\t\t\t\t{ rules: [{ required: false, message: 'Please input your SSN!' }] },\n\t\t\t\t\t\t]"}],attrs:{"placeholder":"ERR446EE)"}})],1),_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Account Type","colon":false}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'account_type',
          { rules: [{ required: true, message: 'Select Account Type' }] } ]),expression:"[\n          'account_type',\n          { rules: [{ required: true, message: 'Select Account Type' }] },\n        ]"}],attrs:{"placeholder":"Select Account Type"},on:{"change":_vm.handleInputChange}},_vm._l((_vm.options),function(option,index){return _c('a-select-option',{key:index,attrs:{"value":option.value}},[_vm._v(" "+_vm._s(option.label)+" ")])}),1)],1),_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Upload Valid Govt Issued ID, Drivers Licence or International Passport","colon":false}},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'file',
          { valuePropName: 'fileList', getValueFromEvent: this.normFile } ]),expression:"[\n          'file',\n          { valuePropName: 'fileList', getValueFromEvent: this.normFile },\n        ]"}],attrs:{"multiple":"","beforeUpload":function () { return false; }}},[_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" Select File ")],1)],1)],1),_c('a-form-item',{staticClass:"mb-10"},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'remember',
						{
							valuePropName: 'checked',
							initialValue: true,
							required: true
						} ]),expression:"[\n\t\t\t\t\t\t'remember',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\tvaluePropName: 'checked',\n\t\t\t\t\t\t\tinitialValue: true,\n\t\t\t\t\t\t\trequired: true\n\t\t\t\t\t\t},\n\t\t\t\t\t\t]"}]},[_vm._v(" I agree the "),_c('a',{staticClass:"font-bold text-dark",attrs:{"href":"#"}},[_vm._v("Terms and Conditions")])])],1),_c('a-form-item',[(!_vm.isLoading)?_c('div',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","block":"","html-type":"submit"}},[_vm._v(" Create Account ")])],1):_c('div',{staticStyle:{"text-align":"center"}},[_c('a-spin',{attrs:{"size":"large"}})],1)]),(_vm.successful)?_c('a-alert',{staticClass:"mb-10",attrs:{"message":"Account created successfully. Login details has been sent to your email. If for some reason, you don't see the email, please check spam or contact support. You may now login!","type":"success","show-icon":""}}):_vm._e(),(_vm.emailExist)?_c('a-alert',{staticClass:"mb-10",attrs:{"message":"Account with this email already exists!","type":"info","show-icon":""}}):_vm._e(),(_vm.failed)?_c('a-alert',{staticClass:"mb-10",attrs:{"message":"An error occurred! Please try again later.","type":"error","show-icon":""}}):_vm._e()],1),_c('p',{staticClass:"font-semibold text-muted text-center"},[_vm._v("Already have an account? "),_c('router-link',{staticClass:"font-bold text-dark",attrs:{"to":"/login"}},[_vm._v("Log In")])],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sign-up-header",staticStyle:{"background-image":"url('images/image2.jpg')"}},[_c('div',{staticClass:"content"},[_c('h1',{staticClass:"mb-5"},[_vm._v("Create An Account")]),_c('p',{staticClass:"text-lg"},[_vm._v("Join Finverge Credit Union today. It's quick and easy to start banking with us.")])])])}]

export { render, staticRenderFns }